import React from "react";
import "./src/common/styles/app.css";
import "@subtropico/shared/src/styles/app.css";
import "animate.css";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { SiteProvider } from "./src/common/components/context/app";
import { ToastProvider } from "../shared/src/contexts/ToastContext";

export const wrapRootElement = ({ element }) => (
    <SiteProvider>
        <ToastProvider>
            <GoogleReCaptchaProvider reCaptchaKey="6LcT0kEjAAAAAG4nSUma7Vb5r7BbeTBpemWuKSJi">
                {element}
            </GoogleReCaptchaProvider>
        </ToastProvider>
    </SiteProvider>
);
